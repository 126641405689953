import React from 'react';
import Layout from '../components/layout';

export default ({ data }) => {
  return (
    <Layout>
      <div>Page not found, sorry</div>
    </Layout>
  );
};
